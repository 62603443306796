import React, { useEffect, useState } from "react";
import PhoneIcon from "../assets/icons/PhoneIcon.png";
import FacebookIcon from "../assets/icons/FacebookIcon.png";
import EmailIcon from "../assets/icons/EmailIcon.png";
import InstaIcon from "../assets/icons/InstaIcon.png";
import LocationIcon from "../assets/icons/LocationIcon.png";
import LinkedInIcon from "../assets/icons/LinkedInIcon.png";
import TiktokIcon from "../assets/icons/TiktokIcon.png";
import { db } from "../firebase";

const ContactUs = () => {
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const phoneNumber = "96103136806";
  const messageToSned = `Name: ${first} ${last} %0aNumber: ${number} %0aEmail: ${email} %0aMessage: ${message}`;

  const handleSubmit = () => {
    if (
      first === "" ||
      last === "" ||
      number === "" ||
      email === "" ||
      message === ""
    ) {
      alert("Please fill all the fields. Thank you.");
      return;
    } else {
      db.collection("Contacts")
        .add({
          firstName: first,
          lastName: last,
          number: number,
          email: email,
          message: message,
        })
        .then(() => {
          window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${messageToSned}`;

          alert("Message has been submitted successfully. Thank you.");
          setEmail("");
          setFirst("");
          setLast("");
          setNumber("");
          setMessage("");
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };

  return (
    <section
      className="h-screen w-full relative pt-12 md:pt-28 md:px-8 px-4 select-none"
      id="ContactUs"
    >
      <h1 className="text-white font-bold text-4xl ml-4 mt-1">Contact Us</h1>
      <div className="w-[100%] flex h-[90%] flex-col md:flex-row overflow-x-scroll">
        <div className="flex h-auto md:h-[100%] w-[100%] md:w-[60%] p-4 flex-col">
          <div className="w-[100%] h-[100%] md:h-[50%] rounded-[15px] bg-gradient-to-r from-[#ffffff67] to-[#ffffff38]  shadow-md shadow-[#00000070] flex flex-wrap p-4 font-bold">
            <div className="w-[100%] md:w-[50%] h-[50%] md:h-[100%] min-w-[300px]">
              <div className="flex flex-row justify-center items-center h-[50%] w-[100%] ">
                <img
                  src={PhoneIcon}
                  alt="Phone Icon"
                  className="w-[10%] h-[50%] object-contain"
                />
                <div className="w-[80%] h-[100%] flex flex-col justify-center ml-2">
                  <p
                    onClick={() => (window.location.href = "tel:03136806")}
                    className="cursor-pointer "
                  >
                    +961 3 136 806
                  </p>
                  <p
                    onClick={() => (window.location.href = "tel:09220603")}
                    className="cursor-pointer "
                  >
                    +961 9 220 603
                  </p>
                </div>
              </div>

              <div
                className="flex flex-row justify-center items-center h-[50%] w-[100%] "
                onClick={() =>
                  (window.location.href = "mailto:info@awesomesmile.com")
                }
              >
                <img
                  src={EmailIcon}
                  alt="Phone Icon"
                  className="w-[10%] h-[50%] object-contain"
                />
                <p className="w-[80%] ml-2">info@awesomesmile.com</p>
              </div>
            </div>
            <div className="w-[100%] md:w-[50%] h-[50%] md:h-[100%] min-w-[300px]">
              <div className="flex flex-row justify-center items-center h-[50%] w-[100%] ">
                <img
                  src={InstaIcon}
                  alt="Phone Icon"
                  className="w-[10%] h-[50%] object-contain"
                />
                <p
                  className="w-[80%] ml-2 cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      "https://www.instagram.com/awesome_smile_clinic/")
                  }
                >
                  awesome_smile_clinic
                </p>
              </div>
              <div
                className="flex flex-row justify-center items-center h-[50%] w-[100%] "
                onClick={() =>
                  (window.location.href =
                    "https://goo.gl/maps/ZH6S6JBgj4zHFJVz6")
                }
              >
                <img
                  src={LocationIcon}
                  alt="Phone Icon"
                  className="w-[10%] h-[50%] object-contain"
                />
                <div className="w-[80%] h-[100%] flex flex-col justify-center ml-2">
                  <p>Zouk Mikael, al boustan street,</p>
                  <p>Bejjani 1739 building, 1st floor.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[100%] md:h-[50%] h-auto ">
            <h1 className="text-white font-bold text-2xl mt-4 mb-2">
              Request Appointment
            </h1>

            <div className="w-[100%] md:h-[80%] flex h-auto  rounded-[15px] bg-gradient-to-r from-[#ffffff67] to-[#ffffff38]  shadow-md shadow-[#00000070]  flex-wrap p-4">
              <div>
                <div className="flex flex-wrap">
                  <div className="flex-col flex mix-w-[200px] flex-1 px-4">
                    <label htmlFor="First">First Name</label>
                    <input
                      type="text"
                      id="First"
                      className="rounded-md"
                      onChange={(e) => setFirst(e.target.value)}
                    />
                  </div>
                  <div className="flex-col flex mix-w-[200px] flex-1 px-4">
                    <label htmlFor="Last">Last Name</label>
                    <input
                      type="text"
                      id="Last"
                      className="rounded-md"
                      onChange={(e) => setLast(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex-col flex mix-w-[200px] flex-1 px-4">
                  <label htmlFor="Number">Number</label>
                  <input
                    type="text"
                    id="Number"
                    className="rounded-md"
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
                <div className="flex-col flex mix-w-[200px] flex-1 px-4">
                  <label htmlFor="Email">Email</label>
                  <input
                    type="text"
                    id="Email"
                    className="rounded-md"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-col flex-1 px-4">
                <label htmlFor="Message">Message</label>
                <textarea
                  onChange={(e) => setMessage(e.target.value)}
                  name="Message"
                  id="Message"
                  rows={5}
                  className="w-full resize-none rounded-md "
                ></textarea>
                <button
                  onClick={handleSubmit}
                  className="ml-auto bg-gradient-to-br from-[#000000] from-[40%]  to-gray-500 to-[90%] text-white rounded-md w-[30%] text-sm py-1 mt-4"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1  h-[100%] w-[100%] md:w-[40%] px-4 md:pl-10 pt-4">
          <iframe
            className="w-full h-[100%] md:h-[60%] rounded-3xl"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.803918774742!2d35.61523438443189!3d33.97187900405289!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f410b52780459%3A0x56b54db5b2e2234b!2sAwesome%20Smile%20by%20Dr.%20Antoine%20Sfeir!5e0!3m2!1sen!2slb!4v1691054972326!5m2!1sen!2slb"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
