import React, { useState, useEffect } from "react";

import { useRef } from "react";
import ScrollArrow from "../assets/icons/ScrollArrow.png";
import ChildImage from "../assets/ChildImage.png";
import ArrowNav from "../assets/ArrowNav2.png";

import { db } from "../firebase";
import { useSelector } from "react-redux";

const Blogs = ({ setBlogs }) => {
  const blogs = useSelector((state) => state.blog.blogs);
  const scrollContainerRef = useRef(null);
  const [details, setDetails] = useState(false);
  // const [blogs, setBlogs] = useState([]);
  const [activeBlog, setActiveBlog] = useState();
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 1550; // You can adjust the scroll amount as needed
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 1550; // You can adjust the scroll amount as needed
    }
  };

  const scroll = () => {
    // Select the section element using the target
    const section = document.getElementById("ContactUs");

    // Scroll to the section
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section
      className="h-screen w-full pt-10 relative md:pt-28 flex flex-col md:flex-row overfolw-y-scroll  "
      id="Blogs"
    >
      <h1 className="absolute text-white font-bold text-4xl ml-5 mt-3">
        Blogs
      </h1>

      {!details && (
        <div
          className=" flex-1 overflow-y-auto overflow-x-hidden "
          style={{ scrollBehavior: "smooth" }}
          ref={scrollContainerRef}
        >
          {blogs.map((blog, index) => (
            <div className="w-[80%] md:h-[30%]  flex-shrink-0 md:flex md:flex-row flex-col  md:pt-3  border-b border-gray-700 mx-auto pb-3">
              <div
                className={`h-[30%] md:w-[20%] w-[100%] ${
                  index === 0 ? "mt-20" : "mt-3"
                }  md:mt-0 md:h-[100%] object-contain mr-4  `}
              >
                <img
                  src={blog.image}
                  alt=""
                  className="h-[100%]   object-contain rounded-2xl mx-auto   "
                />
              </div>

              <div className="w-[100%]   md:w-[80%] md:h-[100%] overflow-y-scroll mt-5 md:mt-0 rounded-2xl   bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] justify-center items-center shadow-lg shadow-[#00000070] px-4 py-2">
                <h1 className="text-black font-bold text-xl md:text-2xl">
                  {blog.title}
                </h1>
                <p className="italic  text-black"> {blog.author}</p>
                <div className="flex md:flex-row flex-col">
                  <p className="flex w-[100%] md:w-[70%] font-bold mt-2">
                    {blog.description.slice(0, 150)}
                  </p>
                  <div className="flex flex-1">
                    <button
                      onClick={() => {
                        setDetails(true);
                        setActiveBlog(blog);
                      }}
                      className=" md:w-[70%] w-[50%] h-[50px] my-4 md:my-0 mx-auto rounded-2xl flex justify-center items-center pl-3 py-2 bg-gradient-to-br from-[#000000] from-[40%]  to-gray-500 to-[90%] text-white"
                    >
                      <p>Read More</p>
                      <img
                        src={ArrowNav}
                        alt=""
                        className="w-[20%] h-[50%] object-contain ml-2"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {details && (
        <div className=" absolute overflow-x-hidden overflow-y-auto w-[90%] md:h-[75%] h-[85%] right-[5%] rounded-t-xl md:top-[25%] top-[15%]  bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] flex md:flex-row flex-col ">
          <div className="w-[100%] md:w-[30%] h-full flex md:flex-col pt-5 justify-between pb-4">
            <img
              src={activeBlog.image}
              className="w-[30%] md:w-[90%] mx-auto object-contain rounded-xl "
              alt=""
            />
            <button
              onClick={() => setDetails(false)}
              className="w-[50%] md:w-[50%] h-[50px] mx-auto rounded-2xl flex justify-center items-center py-2 pl-3 bg-gradient-to-br from-[#000000] from-[40%]  to-gray-500 to-[90%] text-white"
            >
              <p>Read Less</p>
              <img
                src={ArrowNav}
                alt=""
                className="w-[20%] h-[50%] object-contain rotate-90"
              />
            </button>
          </div>
          <div className="w-[100%] md:w-[60%]  p-4 mb-12 md:mb-0    overflow-y-auto ">
            <h1 className="text-black font-bold text-xl md:text-2xl">
              {activeBlog.title}
            </h1>
            <p className="italic  text-black">{activeBlog.author}</p>
            <p className="font-bold mt-4 whitespace-pre-line ">
              {activeBlog.description}
            </p>
          </div>
        </div>
      )}
      {/* {!details && (
        <div
          className="absolute w-[40%] md:w-[10%] h-[30px] bottom-[3%] mb:bottom-5  bg-black  flex justify-center items-center rounded-md cursor-pointer left-[45%]"
          onClick={scroll}
        >
          <p className="text-white">Click To Scroll</p>
        </div>
      )} */}
    </section>
  );
};

export default Blogs;
