import React from "react";
import Navbar from "./components/Navbar";
import Welcome from "./pages/Welcome";
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import Videos from "./pages/Videos";

import Main from "./pages/Main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Main />} />
          <Route path="/home" element={<Main />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/videos" element={<Videos />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
