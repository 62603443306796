import { createSlice } from "@reduxjs/toolkit";
import { db } from "../../firebase";

const blogSlice = createSlice({
    name: "blog",
    initialState: {
        currentBlogsSelected: [],
        AllBlogs: [],
        blogs: [],
        loading: false,
        error: null,
    },
    reducers: {
        getBlogs: (state) => {
            state.loading = true;
        },
        setBlogs: (state, action) => {
            state.blogs = action.payload;
            state.loading = false;
        },
        setAllBlogs: (state, action) => {
            state.AllBlogs = action.payload;
            state.loading = false;
        },
        setBlogTypes: (state, action) => {
            const blogType = action.payload;
            const index = state.currentBlogsSelected.indexOf(blogType);
            if (index > -1) {
                state.currentBlogsSelected.splice(index, 1);
            } else {
                state.currentBlogsSelected.push(blogType);
            }
        },
        clearBlogs: (state) => {
            state.blogs = state.AllBlogs;
            state.currentBlogsSelected = [];
        }
    },
});

export const { getBlogs, setBlogs, setBlogTypes, setAllBlogs, clearBlogs } =
    blogSlice.actions;

export const fetchBlogs = () => async (dispatch) => {
    dispatch(getBlogs());
    try {
        const response = await db.collection("blogs").get();
        const data = response.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        dispatch(setBlogs(data));
        dispatch(setAllBlogs(data));
    } catch (error) {
        console.log(error);
    }
};

export const clearBlogsAction = () => (dispatch) => {
    dispatch(clearBlogs());
};


export const fetchBlogByType = (categoryBlogTitle) => (dispatch, getState) => {
    dispatch(setBlogTypes(categoryBlogTitle));

    if (categoryBlogTitle === "Apply") {

        const state = getState();
        const currentBlogsSelected = state.blog.currentBlogsSelected;
        const AllBlogs = state.blog.AllBlogs;

        if (currentBlogsSelected.length === 0) {
            dispatch(setBlogs(AllBlogs));
        }

        if (currentBlogsSelected.length > 0) {
            const filteredBlogs = AllBlogs.filter((blog) =>
                currentBlogsSelected.includes(blog.category)
            );
            dispatch(setBlogs(filteredBlogs));
        }
    }
};


export const SearchBlogByTitle = (title) => (dispatch, getState) => {
    const { blog } = getState();
    const { AllBlogs } = blog;

    const filteredVideos = AllBlogs.filter((blog) => blog.title.toLowerCase().includes(title.toLowerCase()));
    dispatch(setBlogs(filteredVideos));
};



export default blogSlice.reducer;
