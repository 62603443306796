import React, { useEffect, useState } from "react";
import Logo from "../assets/LogoWhite.png";
import Arrow from "../assets/ArrowNav.png";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import RoundedCheckbox from "./RoundedCheckbox";
import SearchBar from "./SearchBar";
import UpArrowNav from "../assets/UpArrowNav.png";
import DentalProfessionals from "./DentalProfessionals";
import { db } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import { clearBlogsAction, fetchBlogByType } from "../redux/reducers/blogSlice";
import {
  ClearVideosAction,
  fetchVideoByType,
} from "../redux/reducers/videoSlice";
import SearchBarVideo from "./SearchBarVideo";

const Navbar = ({}) => {
  const dispatch = useDispatch();
  const currentBlogsSelected = useSelector(
    (state) => state.blog.currentBlogsSelected
  );
  const currentVideosSelected = useSelector(
    (state) => state.video.currentVideosSelected
  );
  const dentalOrPublic = useSelector((state) => state.video.PublicOrDp);
  const [nav, setNav] = useState(true);
  const [navMobile, setNavMobile] = useState(true);
  const [openNav, setOpenNav] = useState("");
  const [videosChoice, setVideosChoice] = useState("public");

  const [categories, setCategories] = useState([]);

  //----------------------nav desktop controler------------------
  const handleNav = (value) => {
    if (nav === value) {
      setNav("");
    } else {
      setNav(value);
    }
  };

  //--------------------------------------------------

  //----------------------nav mobile------------------
  const handleNavMobile = () => {
    setNavMobile(!navMobile);
  };

  //--------------------------------------------------

  //----------------------checkbox------------------

  const handleChange = (title) => {
    dispatch(fetchBlogByType(title));
  };

  const handleChangeVideo = (id, title) => {
    console.log(currentVideosSelected);
    dispatch(fetchVideoByType(title));
  };
  // ----------------------------------------------

  const scroll = (component) => {
    // Select the section element using the target
    const section = document.getElementById(component);

    // Scroll to the section
    section.scrollIntoView({ behavior: "smooth" });
  };

  const getCategories = () => {
    db.collection("categories").onSnapshot((snapshot) => {
      const categories = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categories);
    });
  };
  useEffect(() => {
    getCategories();
  }, []);
  return (
    <div className="sticky flex z-50 justify-between items-center top-0 left-0 h-30 w-full text-white bg-gradient-to-r from-[#000000] to-gray-300 px-8 select-none">
      <img
        src={Logo}
        className="w-[50%] object-contain h-[80%] md:w-[30%] my-2"
      />
      <ul className="hidden font-bold md:flex ">
        <li
          className={`p-4 cursor-pointer ${nav === "aboutus" && `text-black`}`}
          onClick={() => {
            handleNav("aboutus");

            scroll("Aboutus");
          }}
        >
          About Us
        </li>
        <li
          className={`p-4 cursor-pointer ${nav === "OurTeam" && `text-black`}`}
          onClick={() => {
            handleNav("OurTeam");
            scroll("OurTeam");
          }}
        >
          Our Team
        </li>
        <li
          className={`p-4 cursor-pointer ${
            nav === "ourservices" && `text-black`
          }`}
          onClick={() => {
            handleNav("ourservices");
            scroll("OurServices");
          }}
        >
          Our Services
        </li>
        <li className="p-4 flex items-center cursor-pointer">
          <p
            onClick={() => scroll("Videos")}
            className={` ${nav === "videos" && `text-black`}`}
          >
            Videos
          </p>{" "}
          <img
            src={nav === "videos" ? UpArrowNav : Arrow}
            className="h-2.5 w-2.5 m-2 mt-2 object-contain ease-in-out "
            onClick={() => handleNav("videos")}
          />
          {nav === "videos" && (
            <div className="absolute h-50 w-50 bg-gradient-to-br from-[#000000]  to-[#727272]  top-20 right-10 rounded-[25px] font-normal pb-3">
              <div className="flex w-96 justify-evenly pt-5 ">
                <p
                  className={`${
                    dentalOrPublic === "public" ? "underline" : ""
                  } ${
                    dentalOrPublic !== "public" ? "opacity-25" : ""
                  } cursor-pointer`}
                  onClick={() => {
                    setVideosChoice("public");
                    dispatch(ClearVideosAction());
                  }}
                >
                  Public Content
                </p>
                <p
                  className={`${
                    dentalOrPublic !== "public" ? "underline" : ""
                  } ${
                    dentalOrPublic !== "dp" ? "opacity-25" : ""
                  } cursor-pointer`}
                  onClick={() => setVideosChoice("dental")}
                >
                  Dental Professionals
                </p>
              </div>
              <div className="flex w-full  ">
                <div className="p-4 w-[40%]">
                  {categories
                    .filter(
                      (category) =>
                        category.type === "video" &&
                        (dentalOrPublic === "public"
                          ? category.audience === "public"
                          : category.audience === "DP")
                    )
                    .map((category, index) => (
                      <RoundedCheckbox
                        checked={currentVideosSelected.includes(category.title)}
                        onChange={handleChangeVideo}
                        title={category.title}
                        id={category.id}
                      />
                    ))}
                </div>
                <div className="w-[60%] px-4 pt-4 pl-6">
                  <SearchBarVideo />
                  <div className="w-full flex justify-between flex-1 h ">
                    <button
                      onClick={() => {
                        dispatch(fetchVideoByType("Apply"));
                      }}
                      className="mt-10 w-[40%] bg-gradient-to-r from-[#939393]  to-[#515151] rounded-[25px] py-1 text-black font-bold"
                    >
                      Apply
                    </button>
                    <button
                      onClick={() => {
                        dispatch(ClearVideosAction());
                      }}
                      className="mt-10 w-[40%] bg-gradient-to-r from-[#939393]  to-[#515151] rounded-[25px] py-1 text-black font-bold"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </li>
        <li className="p-4 flex items-center cursor-pointer">
          <p
            onClick={() => scroll("Blogs")}
            className={` ${nav === "blogs" && `text-black`}`}
          >
            Blogs
          </p>{" "}
          <img
            src={nav === "blogs" ? UpArrowNav : Arrow}
            className="h-2.5 w-2.5 m-2 mt-2 object-contain ease-in-out "
            onClick={() => handleNav("blogs")}
          />
          {nav === "blogs" && (
            <div className="absolute h-50 w-50 bg-gradient-to-br from-[#000000]  to-[#727272]  top-20 right-10 rounded-[25px] font-normal pb-3">
              <div className="flex w-full  ">
                <div className="p-4 w-[40%]">
                  {categories
                    .filter((category) => category.type === "blog")
                    .map((category, index) => (
                      <RoundedCheckbox
                        key={category.id}
                        checked={currentBlogsSelected.includes(category.title)}
                        onChange={() => handleChange(category.title)}
                        title={category.title}
                        id={category.id}
                      />
                    ))}
                </div>
                <div className="w-[60%] px-4 pt-4 pl-6">
                  <SearchBar />
                  <div className="w-full flex justify-between flex-1 h ">
                    <button
                      onClick={() => {
                        dispatch(fetchBlogByType("Apply"));
                      }}
                      className="mt-10 w-[40%] bg-gradient-to-r from-[#939393]  to-[#515151] rounded-[25px] py-1 text-black font-bold"
                    >
                      Apply
                    </button>
                    <button
                      onClick={() => {
                        dispatch(clearBlogsAction());
                      }}
                      className="mt-10 w-[40%] bg-gradient-to-r from-[#939393]  to-[#515151] rounded-[25px] py-1 text-black font-bold"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </li>
        <li
          className={`p-4 cursor-pointer ${
            nav === "contactus" && `text-black`
          }`}
          onClick={() => {
            handleNav("contactus");
            scroll("ContactUs");
          }}
        >
          Contact Us
        </li>
      </ul>
      <div
        onClick={() => handleNavMobile(!nav)}
        className="block cursor-pointer md:hidden"
      >
        {!navMobile ? (
          <AiOutlineClose size={20} />
        ) : (
          <AiOutlineMenu size={20} />
        )}
      </div>
      <div
        className={
          !navMobile
            ? "fixed left-0 top-0 w-[70%] border-r border-r-gray-900 bg-black h-full ease-in-out duration-600"
            : "fixed left-[-100%]"
        }
      >
        {!navMobile && (
          <>
            <img src={Logo} className="w-[70%] object-contain md:w-[30%] m-6" />
            <ul className="uppercase p-4">
              <li
                className="p-4 border-b border-b-gray-600 "
                onClick={() => {
                  handleNav("aboutus");
                  handleNavMobile(!navMobile);
                  scroll("Aboutus");
                }}
              >
                About Us
              </li>
              <li
                className="p-4 border-b border-b-gray-600"
                onClick={() => {
                  handleNav("OurTeam");
                  scroll("OurTeam");
                  handleNavMobile(!navMobile);
                }}
              >
                Our Team
              </li>
              <li
                className="p-4 border-b border-b-gray-600"
                onClick={() => {
                  handleNav("ourservices");
                  scroll("OurServices");
                  handleNavMobile(!navMobile);
                }}
              >
                Our Services
              </li>
              <li className="p-4 border-b border-b-gray-600 ">
                <div className="flex">
                  <p
                    onClick={() => {
                      scroll("Videos");
                      handleNavMobile(!navMobile);
                    }}
                    className={`curser-pointer ${
                      nav === "videos" && `text-gray-500`
                    }`}
                  >
                    Videos
                  </p>{" "}
                  <img
                    src={Arrow}
                    className={`h-2.5 w-2.5 m-2 mt-2 object-contain ease-in-out ${
                      nav === "videos" && `transform rotate-180`
                    }`}
                    onClick={() => handleNav("videos")}
                    alt=""
                  />
                </div>
                {nav === "videos" && (
                  <div className=" mt-3 h-50 w-50 bg-gradient-to-br from-[#000000]  to-[#727272]  top-0 right-0 rounded-[25px] font-normal ">
                    <div className="px-4 w-full justify-evenly pt-5 md:flex">
                      <p
                        className={`${
                          videosChoice === "public" ? "underline" : ""
                        } ${
                          videosChoice !== "public" ? "opacity-25" : ""
                        } cursor-pointer`}
                        onClick={() => {
                          setVideosChoice("public");
                          dispatch(ClearVideosAction());
                          handleNavMobile(!navMobile);
                          scroll("Videos");
                        }}
                      >
                        Public Content
                      </p>
                      <p
                        className={`${
                          videosChoice !== "public" ? "underline" : ""
                        } ${
                          videosChoice !== "dental" ? "opacity-25" : ""
                        } cursor-pointer`}
                        onClick={() => setVideosChoice("dental")}
                      >
                        Dental Professionals
                      </p>
                    </div>
                    <div className=" w-full md:flex ">
                      <div className="p-4 w-full md:w-[40%]">
                        {categories
                          .filter(
                            (category) =>
                              category.type === "video" &&
                              (dentalOrPublic === "public"
                                ? category.audience === "public"
                                : category.audience === "DP")
                          )
                          .map((category, index) => (
                            <RoundedCheckbox
                              checked={currentVideosSelected.includes(
                                category.title
                              )}
                              onChange={handleChangeVideo}
                              title={category.title}
                              id={category.id}
                            />
                          ))}
                      </div>
                      <div className="pb-2 px-4 pt-4 pl-6 w-full md:w-[60%]">
                        <SearchBar navType={navMobile} />
                        <div className="w-full flex justify-between flex-1 h ">
                          <button
                            onClick={() => {
                              dispatch(fetchVideoByType("Apply"));
                            }}
                            className="mt-10 w-[40%] bg-gradient-to-r from-[#939393]  to-[#515151] rounded-[25px] py-1 text-black font-bold"
                          >
                            Apply
                          </button>
                          <button
                            onClick={() => {
                              dispatch(ClearVideosAction());
                            }}
                            className="mt-10 w-[40%] bg-gradient-to-r from-[#939393]  to-[#515151] rounded-[25px] py-1 text-black font-bold"
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>
              <li className="p-4 border-b border-b-gray-600 ">
                <div className="flex">
                  <p
                    onClick={() => {
                      scroll("Blogs");
                      handleNavMobile(!navMobile);
                    }}
                    className={`curser-pointer ${
                      nav === "blogs" && `text-gray-500`
                    }`}
                  >
                    Blogs
                  </p>{" "}
                  <img
                    src={Arrow}
                    className={`h-2.5 w-2.5 m-2 mt-2 object-contain ease-in-out ${
                      nav === "blogs" && `transform rotate-180`
                    }`}
                    onClick={() => handleNav("blogs")}
                    alt=""
                  />
                </div>
                {nav === "blogs" && (
                  <div className=" mt-3 h-50 w-50 bg-gradient-to-br from-[#000000]  to-[#727272]  top-0 right-0 rounded-[25px] font-normal ">
                    <div className=" w-full md:flex ">
                      <div className="p-4 w-full md:w-[40%]">
                        {categories
                          .filter((category) => category.type === "blog")
                          .map((category, index) => (
                            <RoundedCheckbox
                              key={category.id}
                              checked={currentBlogsSelected.includes(
                                category.title
                              )}
                              onChange={() => handleChange(category.title)}
                              title={category.title}
                              id={category.id}
                            />
                          ))}
                      </div>
                      <div className="pb-2 px-4 pt-4 pl-6 w-full md:w-[60%]">
                        <SearchBar navType={navMobile} />
                        <div className="w-full flex justify-between flex-1 h ">
                          <button
                            onClick={() => {
                              dispatch(fetchBlogByType("Apply"));
                            }}
                            className="mt-10 w-[40%] bg-gradient-to-r from-[#939393]  to-[#515151] rounded-[25px] py-1 text-black font-bold"
                          >
                            Apply
                          </button>
                          <button
                            onClick={() => {
                              dispatch(clearBlogsAction());
                            }}
                            className="mt-10 w-[40%] bg-gradient-to-r from-[#939393]  to-[#515151] rounded-[25px] py-1 text-black font-bold"
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>

              <li
                className="p-4 "
                onClick={() => {
                  handleNav("contactus");
                  scroll("ContactUs");
                  handleNavMobile(!navMobile);
                }}
              >
                Contact Us
              </li>
            </ul>
          </>
        )}
      </div>
      {videosChoice === "dental" && (
        <DentalProfessionals
          videosChoice={videosChoice}
          setVideosChoice={setVideosChoice}
        />
      )}
    </div>
  );
};

export default Navbar;
