import { createSlice } from "@reduxjs/toolkit";
import { db } from "../../firebase";

const videoSlice = createSlice({
    name: "video",
    initialState: {
        currentVideosSelected: [],
        AllVideos: [],
        videos: [],
        loading: false,
        error: null,
        PublicOrDp: "public",
        dentalProfessionalVideos: [],
    },
    reducers: {
        getVideos: (state) => {
            state.loading = true;
        },
        setVideos: (state, action) => {
            state.videos = action.payload;
            state.loading = false;
        },

        setAllVideos: (state, action) => {
            state.AllVideos = action.payload;
            state.loading = false;
        },
        setVideoTypes: (state, action) => {
            const videoType = action.payload;
            const index = state.currentVideosSelected.indexOf(videoType);
            if (index > -1) {
                state.currentVideosSelected.splice(index, 1);
            } else {
                state.currentVideosSelected.push(videoType);
            }
        },
        clearVideos: (state) => {
            state.videos = state.AllVideos;
            state.currentVideosSelected = [];
            state.PublicOrDp = "public";
        },
        setTypeState: (state, action) => {
            state.PublicOrDp = "dp";
        },
        setDentalProfessionalVideos: (state, action) => {
            state.dentalProfessionalVideos = action.payload;
        },
    },
});

export const { getVideos, setVideos, setAllVideos, setVideoTypes, clearVideos, setDentalProfessionalVideos, setTypeState } =
    videoSlice.actions;


export default videoSlice.reducer;

export const fetchVideos = () => async (dispatch) => {
    dispatch(getVideos());
    try {
        const response = await db.collection("videos").where("audience", "!=", "DP").get();
        const data = response.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        dispatch(setVideos(data));
        dispatch(setAllVideos(data));
    } catch (error) {
        console.log(error);
    }
};

export const fetchDentalProfessionalVideos = (data) => async (dispatch) => {
    dispatch(getVideos());
    dispatch(clearVideos());
    dispatch(setTypeState("dp"));
    await db.collection("DPInfo").add(data);
    try {
        const response = await db.collection("videos").where("audience", "==", "DP").get();
        const data = response.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        dispatch(setVideos(data));
        dispatch(setDentalProfessionalVideos(data));
    } catch (error) {
        console.log(error);
    }
};

export const fetchVideoByType = (categoryVideoTitle) => (dispatch, getState) => {
    dispatch(setVideoTypes(categoryVideoTitle));

    if (categoryVideoTitle === "Apply") {
        const { video } = getState();
        const { currentVideosSelected, AllVideos } = video;

        if (currentVideosSelected.length === 0) {
            dispatch(setVideos(AllVideos));
        }

        if (currentVideosSelected.length > 0) {
            const filteredVideos = AllVideos.filter((video) =>
                currentVideosSelected.includes(video.category)
            );
            dispatch(setVideos(filteredVideos));
        } else {
            dispatch(setVideos(AllVideos));
        }
    }
};

export const ClearVideosAction = () => (dispatch) => {
    dispatch(clearVideos());
};

export const SearchVideoByTitle = (title) => (dispatch, getState) => {
    const { video } = getState();
    const { AllVideos, PublicOrDp, dentalProfessionalVideos } = video;

    let filteredVideos;

    console.log(PublicOrDp);

    if (PublicOrDp === "dp") {
        filteredVideos = dentalProfessionalVideos.filter((video) => video.title.toLowerCase().includes(title.toLowerCase()));
    } else {
        filteredVideos = AllVideos.filter((video) => video.title.toLowerCase().includes(title.toLowerCase()));
    }

    dispatch(setVideos(filteredVideos));
};
