import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { fetchDentalProfessionalVideos } from "../redux/reducers/videoSlice";

const DentalProfessionals = ({ videosChoice, setVideosChoice }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    number: "",
    emailAddress: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNextButtonClick = () => {
    // Do something with the formData, for example, submit it to a server
    dispatch(fetchDentalProfessionalVideos(formData));
    // Close the modal or move to the next step
    setVideosChoice("public");
  };

  return (
    <div className="fixed flex items-center left-0 top-0 backdrop-blur z-50 align-bottom w-[100%] h-[100%]">
      <div className="relative md:h-[80%] h-[100%] flex-col w-[100%] md:w-[60%] flex justify-evenly items-center m-auto bg-gradient-to-br from-[#000000] from-10% via-[#000000] via-30% to-[transparent] to-100% md:rounded-[20px]">
        <div
          className="absolute right-[10px] top-[10px]"
          onClick={() => setVideosChoice("public")}
        >
          <AiOutlineClose size={25} />
        </div>
        <h2 className="underline">Dental Professionals</h2>
        <p className="w-[80%] text-center">
          Disclaimer: This section of the site contains graphic images and
          videos intended for medical professionals and researchers. If you
          would like to proceed, please fill up the below information and click
          “Next”.
        </p>
        <div className="w-[70%] bg-gradient-to-br from-[#ffffff] to-[transparent] rounded-[20px] px-5 py-4 md:px-10 py-7 pb-8 ">
          <div className="flex justify-between flex-wrap ">
            <div className="w-full min-w-[100px] md:w-[48%]">
              <label className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                className="bg-[#FEFEFE] block w-full text-[#000000] sm:text-sm rounded-sm h-[30px]"
              />
            </div>
            <div className="w-full min-w-[100px] md:w-[48%]">
              <label className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className="bg-[#FEFEFE] block w-full text-[#000000] sm:text-sm rounded-sm h-[30px]"
              />
            </div>
          </div>
          <label className="block text-sm font-medium text-gray-700">
            Number
          </label>
          <input
            type="text"
            name="number"
            value={formData.number}
            onChange={handleInputChange}
            className="bg-[#FEFEFE] block w-full text-[#000000] sm:text-sm rounded-sm h-[30px] min-w-[100px]"
          />
          <label className="block text-sm font-medium text-gray-700">
            Email Address
          </label>
          <input
            type="text"
            name="emailAddress"
            value={formData.emailAddress}
            onChange={handleInputChange}
            className="bg-[#FEFEFE] block w-full text-[#000000] sm:text-sm rounded-sm h-[30px] min-w-[100px]"
          />
        </div>
        <button
          className="w-[40%] py-4 bg-gradient-to-br from-[#a6a6a6] to-[transparent] rounded-[15px] text-black font-bold md:w-[25%]"
          onClick={handleNextButtonClick}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default DentalProfessionals;
