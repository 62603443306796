import React from "react";
import SocialsUp from "../assets/SocialsUp.png";
import SocialsWhatsapp from "../assets/SocialsWhatsapp.png";

const SocialLinks = () => {
  const scroll = (component) => {
    // Select the section element using the target
    const section = document.getElementById(component);

    // Scroll to the section
    section.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="absolute h-[10%] bottom-10 right-5 w-[10%] z-30 justify-between flex flex-col items-end">
      <img
        src={SocialsUp}
        alt="up"
        className="h-[45%] object-contain"
        onClick={() => scroll("Welcome")}
      />
      <img
        src={SocialsWhatsapp}
        alt=""
        className="h-[45%] object-contain"
        onClick={() => window.open("https://wa.me/+96103136806", "Hello")}
      />
    </div>
  );
};

export default SocialLinks;
