import React from "react";

const Home = () => {
  const scroll = () => {
    // Select the section element using the target
    const section = document.getElementById("Aboutus");

    // Scroll to the section
    section.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <section className="h-screen flex justify-center" id="Home">
      <div className="flex flex-1">
        <iframe
          src="https://kuula.co/share/collection/7JJwV?logo=-1&card=1&info=1&fs=1&vr=1&zoom=1&sd=1&gyro=0&initload=0&thumbs=1"
          className="object-contain h-screen w-full"
        ></iframe>
      </div>
      <div
        className="absolute w-[40%] md:w-[10%] h-[30px] bottom-[10%] mb:bottom-5  bg-black  flex justify-center items-center rounded-md"
        onClick={scroll}
      >
        <p className="text-white">Click To Scroll</p>
      </div>
    </section>
  );
};

export default Home;
