import React, { useState } from "react";

import UpArrowNav from "../assets/ArrowNav.png";
import { AiOutlineClose } from "react-icons/ai";
import { useRef } from "react";
import ScrollArrow from "../assets/icons/ScrollArrow.png";

import { useSelector } from "react-redux";

const Videos = () => {
  const videos = useSelector((state) => state.video.videos);
  const [details, setDetails] = useState(false);
  const [inVideos, setInVideos] = useState(false);
  const [activeVideo, setActiveVideo] = useState();

  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 1400; // You can adjust the scroll amount as needed
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 1400; // You can adjust the scroll amount as needed
    }
  };

  return (
    <section
      className="h-screen w-full relative pt-10 md:pt-28 flex flex-col md:flex-row"
      id="Videos"
    >
      <div>
        <h1 className="text-white font-bold text-4xl ml-8 mt-5">Videos</h1>
        <p className="underline text-white ml-10">Public Content</p>
      </div>
      <div
        className="flex flex-1 overflow-x-auto overflow-y-hidden "
        style={{ scrollBehavior: "smooth" }}
        ref={scrollContainerRef}
      >
        <button
          onClick={scrollLeft}
          className="left-arrow-btn absolute left-2 md:left-10 top-[48%] md:top-1/2 transform -translate-y-1/2 h-[10%]"
        >
          <img
            src={ScrollArrow}
            alt="arrow"
            className="h-[40%] md:h-[100%] object-contain"
          />
        </button>
        <button
          onClick={scrollRight}
          className="right-arrow-btn absolute right-2 md:right-10 top-[48%] md:top-1/2 transform -translate-y-1/2 h-[10%]"
        >
          <img
            src={ScrollArrow}
            alt="arrow"
            className="rotate-180 h-[40%] md:h-[100%] object-contain"
          />
        </button>
        <div className="w-[100%] h-[80%] md:h-[100%] flex-shrink-0">
          <div className=" w-[90%] mx-auto h-[50%] md:w-[100%]  flex-shrink-0 flex">
            {videos.length > 0 && (
              <div
                onClick={() => {
                  setInVideos(true);
                  setActiveVideo(videos[0]);
                }}
                className="w-[50%] h-[100%] flex flex-col justify-center items-center flex-shrink-0"
              >
                <img
                  src={videos[0].image}
                  alt=""
                  className="rounded-lg h-[50%] object-contain mb-3 cursor-pointer"
                />
                <div className="flex flex-col w-[80%] md:w-[60%] h-[20%] rounded-[15px] bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] justify-center items-center shadow-lg shadow-[#00000070] cursor-pointer">
                  <p className="text-black font-bold text-xs md:text-lg">
                    {videos[0].title}
                  </p>
                  <p className="italic text-xs md:text-md text-center">
                    {videos[0].author}
                  </p>
                </div>
              </div>
            )}

            {videos.length > 1 && (
              <div
                onClick={() => {
                  setInVideos(true);
                  setActiveVideo(videos[1]);
                }}
                className="w-[50%] h-[100%] flex flex-col justify-center items-center flex-shrink-0"
              >
                <img
                  src={videos[1].image}
                  alt=""
                  className="rounded-lg h-[50%] object-contain mb-3 cursor-pointer"
                />
                <div className="flex flex-col w-[80%] md:w-[60%] h-[20%] rounded-[15px] bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] justify-center items-center shadow-lg shadow-[#00000070] cursor-pointer">
                  <p className="text-black font-bold text-xs md:text-lg">
                    {videos[1].title}
                  </p>
                  <p className="italic text-xs md:text-md text-center ">
                    {videos[1].author}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className=" w-[90%] mx-auto md:w-[100%] h-[50%] flex-shrink-0 flex">
            {videos.length > 2 && (
              <div
                onClick={() => {
                  setInVideos(true);
                  setActiveVideo(videos[2]);
                }}
                className="w-[50%] h-[100%] flex flex-col justify-center items-center flex-shrink-0"
              >
                <img
                  src={videos[2].image}
                  alt=""
                  className="rounded-lg h-[50%] object-contain mb-3 cursor-pointer"
                />
                <div className="flex flex-col w-[80%] md:w-[60%] h-[20%] rounded-[15px] bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] justify-center items-center shadow-lg shadow-[#00000070] cursor-pointer">
                  <p className="text-black font-bold text-xs md:text-lg">
                    {videos[2].title}
                  </p>
                  <p className="italic text-xs md:text-md text-center">
                    {videos[2].author}
                  </p>
                </div>
              </div>
            )}
            {videos.length > 3 && (
              <div
                onClick={() => {
                  setInVideos(true);
                  setActiveVideo(videos[3]);
                }}
                className="w-[50%] h-[100%] flex flex-col justify-center items-center flex-shrink-0"
              >
                <img
                  src={videos[3].image}
                  alt=""
                  className="rounded-lg h-[50%] object-contain mb-3 cursor-pointer"
                />
                <div className="flex flex-col w-[80%] md:w-[60%] h-[20%] rounded-[15px] bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] justify-center items-center shadow-lg shadow-[#00000070] cursor-pointer">
                  <p className="text-black font-bold text-xs md:text-lg">
                    {videos[3].title}
                  </p>
                  <p className="italic text-xs md:text-md text-center">
                    {videos[3].author}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        {videos.length > 4 && (
          <div className="w-[100%] h-[80%] md:h-[100%] flex-shrink-0">
            <div className=" w-[90%] mx-auto md:w-[100%] h-[50%] flex-shrink-0 flex">
              <div
                onClick={() => {
                  setInVideos(true);
                  setActiveVideo(videos[4]);
                }}
                className="w-[50%] h-[100%] flex flex-col justify-center items-center flex-shrink-0"
              >
                <img
                  src={videos[4].image}
                  alt=""
                  className="rounded-lg h-[50%] object-contain mb-3 cursor-pointer"
                />
                <div className="flex flex-col w-[80%] md:w-[60%] h-[20%] rounded-[15px] bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] justify-center items-center shadow-lg shadow-[#00000070] cursor-pointer">
                  <p className="text-black font-bold text-xs md:text-lg">
                    {videos[4].title}
                  </p>
                  <p className="italic text-xs md:text-md text-center">
                    {videos[4].author}
                  </p>
                </div>
              </div>
              {videos.length > 5 && (
                <div
                  onClick={() => {
                    setInVideos(true);
                    setActiveVideo(videos[5]);
                  }}
                  className="w-[50%] h-[100%] flex flex-col justify-center items-center flex-shrink-0"
                >
                  <img
                    src={videos[5].image}
                    alt=""
                    className="rounded-lg h-[50%] object-contain mb-3 cursor-pointer"
                  />
                  <div className="flex flex-col w-[80%] md:w-[60%] h-[20%] rounded-[15px] bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] justify-center items-center shadow-lg shadow-[#00000070] cursor-pointer">
                    <p className="text-black font-bold text-xs md:text-lg">
                      {videos[5].title}
                    </p>
                    <p className="italic text-xs md:text-md text-center">
                      {videos[5].author}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className=" w-[90%] mx-auto md:w-[100%] h-[50%] flex-shrink-0 flex">
              {videos.length > 6 && (
                <div
                  onClick={() => {
                    setInVideos(true);
                    setActiveVideo(videos[6]);
                  }}
                  className="w-[50%] h-[100%] flex flex-col justify-center items-center flex-shrink-0"
                >
                  <img
                    src={videos[6].image}
                    alt=""
                    className="rounded-lg h-[50%] object-contain mb-3 cursor-pointer"
                  />
                  <div className="flex flex-col w-[80%] md:w-[60%] h-[20%] rounded-[15px] bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] justify-center items-center shadow-lg shadow-[#00000070] cursor-pointer">
                    <p className="text-black font-bold text-xs md:text-lg">
                      {videos[6].title}
                    </p>
                    <p className="italic text-xs md:text-md text-center">
                      {videos[6].author}
                    </p>
                  </div>
                </div>
              )}
              {videos.length > 7 && (
                <div
                  onClick={() => {
                    setInVideos(true);
                    setActiveVideo(videos[7]);
                  }}
                  className="w-[50%] h-[100%] flex flex-col justify-center items-center flex-shrink-0"
                >
                  <img
                    src={videos[7].image}
                    alt=""
                    className="rounded-lg h-[50%] object-contain mb-3 cursor-pointer"
                  />
                  <div className="flex flex-col w-[80%] md:w-[60%] h-[20%] rounded-[15px] bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] justify-center items-center shadow-lg shadow-[#00000070] cursor-pointer">
                    <p className="text-black font-bold text-xs md:text-lg">
                      {videos[7].title}
                    </p>
                    <p className="italic text-xs md:text-md text-center">
                      {videos[7].author}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {videos.length > 8 && (
          <div className="w-[100%] h-[80%] md:h-[100%] flex-shrink-0">
            <div className=" w-[90%] mx-auto md:w-[100%] h-[50%] flex-shrink-0 flex">
              <div
                onClick={() => {
                  setInVideos(true);
                  setActiveVideo(videos[8]);
                }}
                className="w-[50%] h-[100%] flex flex-col justify-center items-center flex-shrink-0"
              >
                <img
                  src={videos[8].image}
                  alt=""
                  className="rounded-lg h-[50%] object-contain mb-3 cursor-pointer"
                />
                <div className="flex flex-col w-[80%] md:w-[60%] h-[20%] rounded-[15px] bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] justify-center items-center shadow-lg shadow-[#00000070] cursor-pointer">
                  <p className="text-black font-bold text-xs md:text-lg">
                    {videos[8].title}
                  </p>
                  <p className="italic text-xs md:text-md text-center">
                    {videos[8].author}
                  </p>
                </div>
              </div>
              {videos.length > 9 && (
                <div
                  onClick={() => {
                    setInVideos(true);
                    setActiveVideo(videos[9]);
                  }}
                  className="w-[50%] h-[100%] flex flex-col justify-center items-center flex-shrink-0"
                >
                  <img
                    src={videos[9].image}
                    alt=""
                    className="rounded-lg h-[50%] object-contain mb-3 cursor-pointer"
                  />
                  <div className="flex flex-col w-[80%] md:w-[60%] h-[20%] rounded-[15px] bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] justify-center items-center shadow-lg shadow-[#00000070] cursor-pointer">
                    <p className="text-black font-bold text-xs md:text-lg">
                      {videos[9].title}
                    </p>
                    <p className="italic text-xs md:text-md text-center">
                      {videos[9].author}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className=" w-[90%] mx-auto md:w-[100%] h-[50%] flex-shrink-0 flex">
              {videos.length > 10 && (
                <div
                  onClick={() => {
                    setInVideos(true);
                    setActiveVideo(videos[10]);
                  }}
                  className="w-[50%] h-[100%] flex flex-col justify-center items-center flex-shrink-0"
                >
                  <img
                    src={videos[10].image}
                    alt=""
                    className="rounded-lg h-[50%] object-contain mb-3 cursor-pointer"
                  />
                  <div className="flex flex-col w-[80%] md:w-[60%] h-[20%] rounded-[15px] bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] justify-center items-center shadow-lg shadow-[#00000070] cursor-pointer">
                    <p className="text-black font-bold text-xs md:text-lg">
                      {videos[10].title}
                    </p>
                    <p className="italic text-xs md:text-md text-center">
                      {videos[10].author}
                    </p>
                  </div>
                </div>
              )}
              {videos.length > 11 && (
                <div
                  onClick={() => {
                    setInVideos(true);
                    setActiveVideo(videos[11]);
                  }}
                  className="w-[50%] h-[100%] flex flex-col justify-center items-center flex-shrink-0"
                >
                  <img
                    src={videos[11].image}
                    alt=""
                    className="rounded-lg h-[50%] object-contain mb-3 cursor-pointer"
                  />
                  <div className="flex flex-col w-[80%] md:w-[60%] h-[20%] rounded-[15px] bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] justify-center items-center shadow-lg shadow-[#00000070] cursor-pointer">
                    <p className="text-black font-bold text-xs md:text-lg">
                      {videos[11].title}
                    </p>
                    <p className="italic text-xs md:text-md text-center">
                      {videos[11].author}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {inVideos && (
        <div className="fixed flex flex-col  items-center left-0 top-0 w-full  h-full backdrop-blur z-50 align-bottom justify-center items-center">
          <AiOutlineClose
            size={30}
            className="absolute md:right-10 md:top-10 top-2 right-2"
            onClick={() => {
              setInVideos(false);
              setDetails(false);
            }}
          />
          <div className="w-[80%] h-[80%]  rounded-[15px] flex flex-col justify-center items-center">
            <iframe
              src={activeVideo.link}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="object-contain flex h-full w-full rounded-[10px] mx-auto"
            ></iframe>
          </div>
          <div
            className={
              !details
                ? "w-[80%] h-[10%] flex flex-row bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] justify-center items-center shadow-lg shadow-[#00000070] rounded-[10px] mt-2 "
                : `w-[80%] h-[80%]  absolute bottom-8 flex flex-col  bg-gradient-to-r from-[#ffffffec] from-[1%]  to-transparent to-[99%]  shadow-lg shadow-[#00000070] rounded-[10px] mt-2`
            }
          >
            <div
              className={
                details
                  ? "w-[100%] h-[20%] flex flex-col items-center justify-center"
                  : "w-[60%] md:w-[80%] h-[100%] flex flex-col items-center justify-center"
              }
            >
              <p className="font-bold text-md md:text-xl">
                {activeVideo.title}
              </p>
              <p className="italic text-sm">{activeVideo.author}</p>
            </div>
            {details && (
              <div className="w-[80%] h-[65%] md:w-[60%] md:h-[60%] font-bold flex flex-col mx-auto text-justify md:justify-evenly overflow-y-auto">
                <p>{activeVideo.description}</p>
              </div>
            )}

            <div
              className={
                details
                  ? "w-[40%]  md:w-[20%] h-[12%] flex  items-center justify-center absolute bottom-0 right-0"
                  : "w-[40%]  md:w-[20%] h-[100%] flex  items-center justify-center"
              }
            >
              <div
                className="w-[80%] h-[50%] rounded-[15px] flex flex-row items-center justify-center bg-gradient-to-br from-[#000000] from-[40%]  to-gray-500 to-[90%] "
                onClick={() => setDetails(!details)}
              >
                <p className="text-white text-sm">Read More</p>
                <img
                  src={UpArrowNav}
                  alt=""
                  className={
                    details
                      ? "h-3 w-3 m-2 mt-2 object-contain ease-in-out"
                      : "h-3 w-3 m-2 mt-2 object-contain ease-in-out  rotate-180"
                  }
                />
              </div>
            </div>
          </div>
          {details && <div className="w-[80%] h-[10%] flex "></div>}
        </div>
      )}
    </section>
  );
};

export default Videos;
