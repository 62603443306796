import { configureStore } from "@reduxjs/toolkit";
import blogReducer from "./reducers/blogSlice";
import videoReducer from "./reducers/videoSlice";

export default configureStore({
    reducer: {
        blog: blogReducer,
        video: videoReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
    })
});