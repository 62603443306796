




import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBgsgBGj0LeK4CQLOgAXS5x5huWfX6szDM",
    authDomain: "awesome-smile.firebaseapp.com",
    projectId: "awesome-smile",
    storageBucket: "awesome-smile.appspot.com",
    messagingSenderId: "773833181491",
    appId: "1:773833181491:web:ccf88cba9de5d4651e5646",
    measurementId: "G-G3WM7QJ1EV"

};

let app = firebase.initializeApp(firebaseConfig);


const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
db.settings({ experimentalForceLongPolling: true });


export { auth, db, storage, firebase };