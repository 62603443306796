import React, { useState } from "react";

import { useRef } from "react";
import ScrollWhiteArrow from "../assets/icons/ScrollWhiteArrow.png";
import DocImage from "../assets/DocImage.png";
import AboutusImage from "../assets/AboutusImage.png";

import DrAntoine from "../assets/ImagesSide/DrAntoine.png";
import DrCecile from "../assets/ImagesSide/DrCecile.png";
import DrChristina from "../assets/ImagesSide/DrChristina.png";
import DrGhinwa from "../assets/ImagesSide/DrGhinwa.png";
import DrJane from "../assets/ImagesSide/DrJane.png";
import DrMarc from "../assets/ImagesSide/DrMarc.png";
import DrMarilyneEddo from "../assets/ImagesSide/DrMarilyneEddo.png";
import DrRabihHarmouch from "../assets/ImagesSide/DrRabihHarmouch.png";
import ElyssaKhoury from "../assets/ImagesSide/ElyssaKhoury.png";
import Hermella from "../assets/ImagesSide/Hermella.png";
import MyriamKhoury from "../assets/ImagesSide/MyriamKhoury.png";
import RaghidaBeaino from "../assets/ImagesSide/RaghidaBeaino.png";

const OurTeam = () => {
  const scrollContainerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [indicator, setIndicator] = useState(0);

  const data = [
    {
      name: "Dr. Antoine Sfeir",
      title: "Founder, Periodontist & Implantologist",
      image: DrAntoine,
      details: !isMobile
        ? `Dr. Antoine, a distinguished periodontist and expert in TMJ disorders and sleep apnea, extends a warm invitation to embark on an extraordinary dental journey. Immerse yourself in a realm of sophistication and refinement as he takes you through a spectrum of specialized services. Dr. Antoine's expertise encompasses precise diagnosis and treatment of gum and bone diseases, encompassing dental implants, jaw joint conditions, and sleep-related breathing problems. With an accomplished team of professionals and state-of-the-art facilities, 'Awesome Smile' guarantees a voyage that transcends all expectations. Our commitment is to elevate your oral health and craft awe-inspiring smiles with meticulous care and precision. Step into this world and bear witness to the harmonious blend of aesthetics and dental perfection. Here, dreams are given life—one radiant smile at a time.`
        : `Join Dr. Antoine, a leading periodontist and TMJ specialist, on a journey to dental excellence. Explore specialized services including implants, jaw care, and sleep solutions at 'Awesome Smile'. Experience the fusion of aesthetics and precision, crafting radiant smiles and fulfilling dreams, one smile at a time.`,
    },
    {
      name: "Dr. Cecile Freiha",
      title: "Periodontist & Implantologist",
      image: DrCecile,
      details: `DDS and Msc in periodontology. Dr Freiha is a graduate of the Faculty of dental medecine of the University Saint Joseph of Beirut with a master degree in Periodontology and Implantology.`,
    },
    {
      name: "Dr. Christina Kai",
      title: "General practitioner",
      image: DrChristina,
      details: `General practitioner, graduate of the Faculty of dental medecine of the Lebanese University with a degree in doctor of dental surgery`,
    },
    {
      name: "Dr. Ghenwa Touma",
      title: "General practitioner",
      image: DrGhinwa,
      details: `General practitioner, graduate of the Faculty of dental medecine of the Lebanese University with a degree in doctor of dental surgery`,
    },
    {
      name: "Dr. Jane Khoury",
      title: "Pediatric Dentist",
      image: DrJane,
      details: `DDS , DU and Msc in Pediatric Dentistry. Dr Jane is graduated from the Saint Joseph University Beirut with a Doctor of Dental Surgery degree , a university diploma and Master’s degree in Pediatric Dentistry.`,
    },
    {
      name: "Dr. Marc Khoury",
      title: "Endodontist, DDS & MSc",
      image: DrMarc,
      details: `Msc Biology, DDS, CES Endodontics Dr Khoury is a graduate of the Faculty of Science of the Lebanese University with a master degree in biological sciences: concentration in physiology. Dr Khoury is a graduate of the Faculty of dental medicine of the University Saint Joseph of Beirut with a graduate certificate in Endodontics. `,
    },
    {
      name: "Dr. Marilyne Eddo",
      title: "Orthodontist",
      image: DrMarilyneEddo,
      details: `DDS - MS in Orthodontics and Dento-Facial Orthopedics. Dr Eddo is graduated from Saint Joseph University in Beirut with a Doctor of Dental Surgery degree from the Faculty of Dental Medicine and a Master’s degree in Orthodontics and Dento-Facial Orthopedics.`,
    },
    {
      name: "Dr. Rabih Harmouch",
      title: "Orthodontist",
      image: DrRabihHarmouch,
      details: `DDS, MS in Orthodontics and Dentofacial Orthopedics. Graduate of the faculty of dental medicine of the Saint Joseph University with a master degree in Orthodontics.`,
    },
    {
      name: "Raghida Beaino",
      title: "Clinic Manager",
      image: RaghidaBeaino,
      details: !isMobile
        ? `Driving the daily operation of the dental clinic, including the supervision and clinical oversight of dental assistants and hygienists. Establishing staff training requirements as well as facilitating completion of same and management of patient concerns. Responsible for the efficiency of dental clinic operations including patient scheduling and ensuring adequate staffing levels for all dental staff as well as supply ordering and adhering to a budget. Responsible for productivity and efficiency of the dental department by maximizing the schedule, and decreasing the no-show rate. Assisting and working with the Dental Director in establishing specific goals; determining work procedures and expediting workflow.Providing daily reports and other periodic reports as required. Acting as a leader by building constructive relationships and supporting the clinic team in developing, setting, and executing clinic goals.`
        : `Leading daily dental clinic operations, supervising staff, and optimizing patient care. Managing schedules, staffing, supplies, and budgets for efficiency. Collaborating with the Dental Director to set goals and enhance workflows. Building strong relationships and supporting the team to achieve clinic objectives.`,
    },
    {
      name: "Elyssa Khoury",
      title: "Dental Assistant",
      image: ElyssaKhoury,
      details: !isMobile
        ? `An assistant who excels at multitasking, juggling seamless appointment scheduling, patient care, and maintaining strong communication skills. My ability to efficiently manage appointments ensures a well-organized clinic schedule. My friendly and reassuring manner puts patients at ease and makes their dental experience more enjoyable!`
        : `A proficient multitasker, I excel in seamlessly managing appointments, patient care, and effective communication. My skill in scheduling ensures a well-organized clinic, while my friendly approach creates a comfortable and enjoyable dental experience for patients.`,
    },
    {
      name: "Myriam Khoury",
      title: "Dental Assistant",
      image: MyriamKhoury,
      details: `As a dental assistant, I work closely with dentists and hygienists to provide essential support in the dental office .My ability to efficiently manage appointments ensures a well-organized clinic schedule My responsibilities may include setting up treatment rooms, assisting during procedures, and ensuring patients feel comfortable!`,
    },

    {
      name: "Hermella Mellis",
      title: "Custodian",
      image: Hermella,
      details: `My job in the dental clinic entails providing support to dentists and dental hygienists during procedures and treatments. My responsibilities include preparing dental instruments, preparing treatment rooms, sterilizing equipment, and assisting with patient care. My role requires excellent organizational skills, attention to infection control protocols, and the ability to maintain a clean and safe environment.`,
    },
  ];

  const scrollLeft = () => {
    if (indicator !== 0) {
      setIndicator(indicator - 1);
    }
  };

  const scrollRight = () => {
    if (indicator !== data.length - 1) {
      setIndicator(indicator + 1);
    }
  };
  return (
    <section
      className="h-screen w-full relative pt-10 md:pt-28 flex flex-col md:flex-row overfolw-y-scroll"
      id="OurTeam"
    >
      <div
        className="flex flex-1 overflow-x-auto overflow-y-hidden "
        style={{ scrollBehavior: "smooth" }}
        ref={scrollContainerRef}
      >
        <div className="absolute bottom-[10%] md:bottom-0 w-[100%] md:w-[50%] left-0 md:left-10 h-[10%] md:h-[20%]   bg-gradient-to-b from-[#000000d0] from-10% to-[transparent] to-100% rounded-t-2xl flex flex-row pt-2 md:pt-10  justify-between px-16">
          <div
            className="h-[50%] md:h-[50%] object-contain my-auto  justify-center items-center flex px-2"
            onClick={scrollLeft}
          >
            <img
              src={ScrollWhiteArrow}
              alt=""
              className="h-[50%] md:h-[50%] object-contain my-auto  "
            />
          </div>
          <div className="h-[100%] flex flex-col justify-center">
            <h1 className="text-white font-bold text-xl md:text-4xl mx-auto">
              {data[indicator].name}
            </h1>
            <p className="italic mx-auto text-white text-center">
              {data[indicator].title}
            </p>
          </div>
          <div
            className="h-[50%] md:h-[50%] object-contain my-auto justify-center items-center flex px-2"
            onClick={scrollRight}
          >
            <img
              src={ScrollWhiteArrow}
              alt=""
              className="h-[50%] md:h-[50%] object-contain my-auto rotate-180"
            />
          </div>
        </div>
        <div className="w-[100%] h-[100%] flex-shrink-0 ">
          <div className="w-[100%]  md:w-[90%] h-[100%] bg-gradient-to-br from-[#000000] from-20% to-[transparent] to-100% rounded-r-[20px] flex flex-col-reverse md:flex-row ">
            <div className="flex flex-col md:flex-1 md:h-[100%] w-[100%] h-[60%] mb-[8%] md:mb-0 ">
              <img
                src={data[indicator].image}
                alt=""
                className="w-[100%] h-[100%] object-contain md:ml-[15%] "
              />
            </div>
            <div className="flex flex-1 pt-4 pl-8  md:pt-[7%] flex-col  md:h-[100%] h-[30%]">
              <h1 className="text-white flex font-bold text-4xl md:text-4xl ">
                Our Team
              </h1>
              <p className="text-white mt-5 max-w-[90%] h-max  whitespace-pre-line ">
                {data[indicator].details}
              </p>
              <p className="text-white mt-5 max-w-[90%]"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
