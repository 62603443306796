import React from "react";
import AboutusImage from "../assets/AboutusImage.png";

const Aboutus = () => {
  return (
    <section
      className="h-screen w-full  pl-[2%] relative pt-10 md:pt-28 "
      id="Aboutus"
    >
      <h1 className="text-white font-bold text-4xl ml-5 mt-2">About Us</h1>
      <div className="w-full  bg-gradient-to-br from-[#000000] from-10% to-[transparent] to-100% rounded-l-[20px] mt-2 mb-2 md:h-[60%] ">
        <div className="h-[100%] w-[100%] py-4 px-4 md:w-[50%]">
          <p className="text-white font-bold text-sm md:text-xl">
            At Awesome Smile Dental Clinic, we take pride in being your partners
            on the journey to optimal oral health and radiant smiles. Our
            passionate team of dental experts is wholeheartedly devoted to
            delivering unparalleled care while assisting our valued patients in
            attaining both wellness and stunning smiles.
          </p>
          <p className="text-white font-bold text-sm  mt-5 md:text-xl">
            With an extensive array of comprehensive services and cutting-edge
            technology at our disposal, combined with an inviting and welcoming
            atmosphere, our utmost goal is to ensure that your dental encounters
            are marked by comfort and a sense of ease. Your satisfaction and
            relaxation matter to us, making your visit to our clinic a
            stress-free and comfortable experience.
          </p>
        </div>
      </div>
      <img
        src={AboutusImage}
        alt=""
        className="absolute bottom-[7%] md:bottom-0 right-0 w-[75%]  md:w-auto md:h-[80%]  object-contain "
      />
    </section>
  );
};

export default Aboutus;
