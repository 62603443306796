import React from "react";

const RoundedCheckbox = ({ checked, onChange, title, id }) => {
  return (
    <label className="flex items-center cursor-pointer">
      <div className="relative flex items-center">
        <input
          type="checkbox"
          className="hidden"
          checked={checked}
          onChange={() => {
            onChange(id, title);
          }}
        />
        <div className="w-3 h-3 rounded-full border border-gray-400 bg-transparent transition-all duration-300">
          {checked && (
            <svg
              className="w-2.5 h-2.5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
        </div>
      </div>
      <span className="ml-2 text-white-700">{title}</span>
    </label>
  );
};

export default RoundedCheckbox;
