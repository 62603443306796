import React, { useState, useEffect } from "react";
import { useRef } from "react";
import ScrollArrow from "../assets/icons/ScrollWhiteArrow.png";
import VideoImage from "../assets/VideoImage.png";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { db } from "../firebase";
import { AiOutlineClose } from "react-icons/ai";
import ArrowNav from "../assets/ArrowNav2.png";

const OurServices = () => {
  const scrollContainerRef = useRef(null);
  const [services, setServices] = useState([]);

  const getServices = () => {
    db.collection("service")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        setServices(data);
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  const [booking, setBooking] = useState("scroll");
  const [tempData, setTempData] = useState([]);
  const [formAppear, setFormAppear] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleDateChange = (date) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };

    const tempDate = new Intl.DateTimeFormat("en-US", options).format(date);
    setSelectedDate(tempDate);
  };

  function moveFirstToEnd(arr) {
    if (arr.length <= 1) {
      return arr;
    }

    const firstElement = arr.shift();
    arr.push(firstElement);
    return arr;
  }

  const handleMoveFirstToEnd = () => {
    const newArray = moveFirstToEnd([...services]);
    setServices(newArray);
  };

  function moveEndToFront(arr) {
    if (arr.length <= 1) {
      return arr;
    }

    const lastElement = arr.pop();
    arr.unshift(lastElement);
    return [...arr]; // Return a new array to trigger state update
  }

  const handleMoveEndToFront = () => {
    const newArray = moveEndToFront([...services]);
    setServices(newArray);
  };

  const phoneNumber = "96103136806";
  const messageToSned = `Name: ${first} ${last} %0aNumber: ${number} %0aEmail: ${email}%0aService: ${tempData.title} %0aDate: ${selectedDate} %0aMessage: ${message}`;

  const handleSubmit = () => {
    if (
      first === "" ||
      last === "" ||
      number === "" ||
      email === "" ||
      message === "" ||
      selectedDate === ""
    ) {
      alert("Please fill all the fields. Thank you.");
      return;
    } else {
      db.collection("Appointments")
        .add({
          firstName: first,
          lastName: last,
          number: number,
          email: email,
          message: message,
          service: tempData.title,
          date: selectedDate,
        })
        .then(() => {
          window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${messageToSned}`;

          alert("Message has been submitted successfully. Thank you.");
          setEmail("");
          setFirst("");
          setLast("");
          setNumber("");
          setMessage("");
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };

  return (
    <section
      className="h-screen w-full   relative pt-10 md:pt-28 flex flex-col "
      id="OurServices"
    >
      <h1 className="text-white font-bold text-4xl ml-5 md:mt-2 mt-4">
        Our Services
      </h1>
      {booking === "scroll" && (
        <div
          className="flex flex-1 overflow-x-auto overflow-y-hidden md:pl-40"
          style={{ scrollBehavior: "smooth" }}
          ref={scrollContainerRef}
        >
          <button
            onClick={handleMoveEndToFront}
            className="left-arrow-btn absolute left-2 md:left-10 top-[48%] md:top-1/2 transform -translate-y-1/2 h-[10%] z-40"
          >
            <img
              src={ScrollArrow}
              alt="arrow"
              className="h-[40%] md:h-[100%] object-contain opacity-50"
            />
          </button>
          <button
            onClick={handleMoveFirstToEnd}
            className="right-arrow-btn absolute right-2 md:right-10 top-[48%] md:top-1/2 transform -translate-y-1/2 h-[10%] z-40 opacity-50"
          >
            <img
              src={ScrollArrow}
              alt="arrow"
              className="rotate-180 h-[40%] md:h-[100%] object-contain"
            />
          </button>
          {services.map((item, index) => (
            <div
              className={`w-[100%] md:w-[33%] md:h-full h-[90%] p-4 flex-shrink-0 scale-90  "scale-90 md:scale-100 `}
            >
              <div className="h-full w-full bg-gradient-to-br from-[#000000] from-10% to-[transparent] to-100% rounded-2xl flex flex-col items-center justify-evenly p-4">
                <img
                  src={item.image}
                  alt="image"
                  className=" mx-auto h-[20%] object-contain rounded-lg"
                />
                <h1 className="text-white font-bold text-xl">{item.title}</h1>
                <p className="text-center text-white h-[30%]">
                  {item.subTitle}
                </p>
                <button
                  className="w-[80%] p-4 font-bold rounded-2xl shadow-lg shadow-[#00000070] bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%]"
                  onClick={() => {
                    setBooking("calender");
                    setTempData(item);
                  }}
                >
                  Book an appointment
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      {booking === "calender" && (
        <div className="w-[85%] mt-3 mx-auto h-[85%] bg-gradient-to-br from-[#000000b6] from-40% to-[transparent] to-100% rounded-[20px] flex md:flex-row flex-col overflow-y-auto relative">
          <button
            onClick={() => {
              if (formAppear) {
                setFormAppear(false);
              } else {
                setBooking("scroll");
              }
            }}
            className="absolute top-2 left-2 md:top-10 md:left-10 flex items-center z-40"
          >
            <img src={ArrowNav} alt="" className="rotate-180" />
            <p className="text-white text-lg ml-2">Back</p>
          </button>
          <div
            className={`w-[100%]  md:w-[30%] h-[70%] md:h-full p-4 flex-shrink-0 scale-90 `}
          >
            <div className="h-full w-full rounded-2xl flex flex-col items-center justify-evenly p-4">
              <img
                src={tempData.image}
                alt="image"
                className="w-[50%] mx-auto"
              />
              <h1 className="text-white font-bold text-xl">{tempData.title}</h1>
              <p className="text-center text-white">{tempData.subTitle}</p>
            </div>
          </div>
          {!formAppear && (
            <div className="w-[100%] mx-auto md:w-[70%] h-full  justify-center items-center flex flex-col">
              <Calendar
                className="rounded-lg scale-90 md:scale-125"
                minDate={new Date()}
                tileDisabled={({ date }) => date.getDay("Sunday") === 0}
                value={selectedDate}
                onChange={handleDateChange}
              />

              <button
                onClick={() => setFormAppear(true)}
                className="w-[50%] text-black font-bold  bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] mt-8 mb-4 md:mb-0 md:mt-12 h-[40px] md:h-[10%] rounded-2xl"
              >
                Next
              </button>
            </div>
          )}
          {formAppear && (
            <div className="w-[100%] mx-auto md:w-[70%] h-full  justify-center items-center flex flex-col  ">
              <div className="h-[80%] md:h-[70%] w-[90%] md:w-[60%] bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] rounded-2xl px2 md:px-4 py-4 md:py-7 ">
                <div>
                  <div className="flex flex-wrap">
                    <div className="flex-col flex mix-w-[200px] flex-1 px-4">
                      <label htmlFor="First">First Name</label>
                      <input
                        type="text"
                        id="First"
                        className="rounded-md"
                        onChange={(e) => setFirst(e.target.value)}
                      />
                    </div>
                    <div className="flex-col flex mix-w-[200px] flex-1 px-4">
                      <label htmlFor="Last">Last Name</label>
                      <input
                        type="text"
                        id="Last"
                        className="rounded-md"
                        onChange={(e) => setLast(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex-col flex mix-w-[200px] flex-1 px-4">
                    <label htmlFor="Number">Number</label>
                    <input
                      type="text"
                      id="Number"
                      className="rounded-md"
                      onChange={(e) => setNumber(e.target.value)}
                    />
                  </div>
                  <div className="flex-col flex mix-w-[200px] flex-1 px-4">
                    <label htmlFor="Email">Email Address</label>
                    <input
                      type="text"
                      id="Email"
                      className="rounded-md"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-col flex-1 px-4">
                  <label htmlFor="Message">Message</label>
                  <textarea
                    onChange={(e) => setMessage(e.target.value)}
                    name="Message"
                    id="Message"
                    rows={5}
                    className="w-full resize-none rounded-md "
                  ></textarea>
                </div>
              </div>
              <button
                onClick={handleSubmit}
                className="w-[60%] text-black font-bold  bg-gradient-to-r from-[#ffffff67] from-[1%] via-transparent via-[50%] to-gray-300 to-[99%] mt-8 mb-4 md:mb-0 md:mt-12 h-[40px] md:h-[10%] rounded-2xl"
              >
                Submit
              </button>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default OurServices;
