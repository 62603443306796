import React from "react";
import { FaSearch } from "react-icons/fa";
import SearchIcon from "../assets/SearchIcon.png";
import { useDispatch } from "react-redux";
import { SearchBlogByTitle } from "../redux/reducers/blogSlice";

const SearchBar = (props) => {
  const dispatch = useDispatch();
  return (
    <div className="flex  items-center bg-[#D9D9D9] border border-gray-300 rounded-md px-2 ">
      <input
        navType="text"
        className="flex-grow  outline-none bg-transparent font-bold text-black w-full"
        placeholder="Teeth whitening...."
        onChange={(e) => {
          dispatch(SearchBlogByTitle(e.target.value));
        }}
      />
      <img
        src={SearchIcon}
        className={`text-gray-500 ml-2 z-50 h-4 w-4  
        `}
      />
    </div>
  );
};

export default SearchBar;
