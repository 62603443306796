import React, { useState } from "react";
import Logo from "../assets/LogoBlack.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-coverflow";
import Logo2 from "../assets/icons/Logo5.png";
import ScrollArrow from "../assets/icons/ScrollWhiteArrow.png";

import Mark1 from "../assets/finalfront/DrMarc.png";
import Cecile2 from "../assets/finalfront/DrCecile.png";
import Antoine3 from "../assets/finalfront/Drantoine.png";
import Jane6 from "../assets/finalfront/DrJane.png";
import Christina7 from "../assets/finalfront/DrChristina.png";
import Ghinwa8 from "../assets/finalfront/DrGhinwa.png";
import Marilyne4 from "../assets/finalfront/DrMarilyneEddo.png";
import Rabih5 from "../assets/finalfront/DrRabihHarmouch.png";

import fingureright from "../assets/fingureRight.png";
import fingureLeft from "../assets/fingureLeft.png";

const Welcome = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const Data = !isMobile
    ? [
        {
          image: Mark1,
          name: "Dr. Marc Khoury",
          title: "DDS, Msc in Biology, Physiology and Endodontics",
        },
        {
          image: Cecile2,
          name: "Dr. Cécile Freiha",
          title: "DDS and Msc in periodontology and implantology",
        },
        {
          image: Antoine3,
          name: "Dr. Antoine Sfeir",
          title:
            "DDS, UD in occlusion and TMJ disorders, Msc in periodontology and implantology",
        },
        {
          image: Marilyne4,
          name: "Dr. Marilyn Eddo",
          title: "DDS, Msc in Orthodontics and Dentofacial Orthopedics.",
        },
        {
          image: Rabih5,
          name: "Dr. Rabih Harmouch",
          title: "DDS, Msc in Orthodontics and Dentofacial Orthopedics.",
        },
        {
          image: Jane6,
          name: "Dr. Jane khoury",
          title: "DDS, UD and Msc in Pediatric Dentistry.",
        },
        {
          image: Christina7,
          name: "Dr. Christina Kai",
          title: "General practitioner",
        },
        {
          image: Ghinwa8,
          name: "Dr. Ghenwa TOUMA",
          title: "General practitioner",
        },
      ]
    : [
        {
          image: Antoine3,
          name: "Dr. Antoine Sfeir",
          title:
            "DDS, UD in occlusion and TMJ disorders, Msc in periodontology and implantology",
        },
        {
          image: Mark1,
          name: "Dr. Marc Khoury",
          title: "DDS, Msc in Biology, Physiology and Endodontics",
        },
        {
          image: Cecile2,
          name: "Dr. Cécile Freiha",
          title: "DDS and Msc in periodontology and implantology",
        },

        {
          image: Marilyne4,
          name: "Dr. Marilyn Eddo",
          title: "DDS, Msc in Orthodontics and Dentofacial Orthopedics.",
        },
        {
          image: Rabih5,
          name: "Dr. Rabih Harmouch",
          title: "DDS, Msc in Orthodontics and Dentofacial Orthopedics.",
        },
        {
          image: Jane6,
          name: "Dr. Jane khoury",
          title: "DDS, UD and Msc in Pediatric Dentistry.",
        },
        {
          image: Christina7,
          name: "Dr. Christina Kai",
          title: "General practitioner",
        },
        {
          image: Ghinwa8,
          name: "Dr. Ghenwa TOUMA",
          title: "General practitioner",
        },
      ];
  const [hoveredSlideIndex, setHoveredSlideIndex] = useState(-1);

  const handleSlideHover = (index) => {
    setHoveredSlideIndex(index);
  };

  // Event handler for slide leave
  const handleSlideLeave = () => {
    setHoveredSlideIndex(-1);
  };
  const [indicator, setIndicator] = useState(false);
  const [indicator2, setIndicator2] = useState(true);
  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;

    // Check if the active slide index is 0
    if (activeIndex === 0) {
      setIndicator(false); // First slide
    } else if (activeIndex === 7) {
      setIndicator2(false); // Not the first slide
    } else if (!isMobile && activeIndex === 3) {
      setIndicator2(false); // Not the first slide
    } else {
      setIndicator(true); // Not the first slide
      setIndicator2(true); // Not the first slide
    }
  };
  return (
    <section
      className="h-[100%] w-[100%] z-100 items-center bg-gradient-to-r from-[#ffffff79] from-10% to-[#000000ca] to-100% z-50 "
      id="Welcome"
    >
      {indicator && (
        <div className="left-arrow-btn absolute left-2 md:left-10 top-[48%] md:top-1/2 transform -translate-y-1/2 h-[7%] z-40 flex flex-col justify-center items-center">
          <img
            src={fingureLeft}
            alt="arrow"
            className=" h-[40%] md:h-[100%] object-contain opacity-50"
          />
          <p className="text-sm">Swipe</p>
        </div>
      )}
      {indicator2 && (
        <div className="right-arrow-btn absolute right-2 md:right-10 top-[48%] md:top-1/2 transform -translate-y-1/2 h-[7%] z-40 flex flex-col justify-center items-center ">
          <img
            src={fingureright}
            alt="arrow"
            className=" h-[40%] md:h-[100%] object-contain opacity-50"
          />
          <p className="text-sm">Swipe</p>
        </div>
      )}

      <img
        src={Logo2}
        className="absolute z-1 scale-80 w-[80%] object-contain left-[10%] md:top-4  top-20"
        alt=""
      />
      <Swiper
        modules={[EffectCoverflow]}
        loop={false}
        effect="coverflow"
        className="h-[100%] w-[100%] mx-auto"
        slidesPerView={isMobile ? 1 : 5}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false,
        }}
        onSlideChange={handleSlideChange}
      >
        {Data.map((image, index) => (
          <SwiperSlide
            onMouseEnter={() => handleSlideHover(index)}
            onMouseLeave={handleSlideLeave}
            style={
              hoveredSlideIndex === index && !isMobile
                ? { zIndex: 50 }
                : index === 2
                ? { zIndex: 49 }
                : { zIndex: 0 }
            }
          >
            <img
              src={image.image}
              className={`absolute ${
                isMobile ? "bottom-0" : "bottom-[5%]"
              }  w-[100%] object-contain transition-all duration-300 ease-in-out ${
                hoveredSlideIndex === index && !isMobile
                  ? "scale-[160%]"
                  : isMobile
                  ? "scale-100"
                  : "scale-[120%]"
              }`}
              alt=""
            />
            <div className="w-[90%] py-4 px-4 bg-gradient-to-b from-[#000000b2] from-10% to-[#81818139] to-100% rounded-[15px] absolute bottom-2 left-[5%] flex flex-col justify-center items-center   ">
              <h1 className="text-white text-xl font-bold">{image.name}</h1>
              {/* <h1 className="text-white text-md ">Orthodontist</h1> */}

              {!isMobile && (
                <p
                  className={` transition-all duration-800 ease-in-out ${
                    hoveredSlideIndex === index
                      ? "text-[16px] text-white mt-4"
                      : "text-[1px] text-[transparent]"
                  }  text-center`}
                >
                  {image.title}
                </p>
              )}
              {isMobile && (
                <p
                  className={` transition-all duration-400 ease-in-out 
                   "text-[16px] text-white mt-4"
                 text-center`}
                >
                  {image.title}
                </p>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Welcome;
