import React, { useState, useEffect } from "react";
import Welcome from "./Welcome";
import Home from "./Home";
import Navbar from "../components/Navbar";
import Aboutus from "./Aboutus";
import ContactUs from "./ContactUs";
import Videos from "./Videos";
import OurTeam from "./OurTeam";
import SocialLinks from "../components/SocialLinks";
import Blogs from "./Blogs";
import OurServices from "./OurServices";
import { db } from "../firebase";
import { useDispatch } from "react-redux";
import { fetchBlogs } from "../redux/reducers/blogSlice";
import { fetchVideos } from "../redux/reducers/videoSlice";

const Main = () => {
  const dispatch = useDispatch();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    dispatch(fetchBlogs());
    dispatch(fetchVideos());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="Main">
      <Welcome />
      <div className="z-10">
        <Navbar />
        <SocialLinks />
        <Home />
        <Aboutus />
        <OurTeam />
        <OurServices />
        <Videos />
        <Blogs />
        <ContactUs />
      </div>
    </div>
  );
};

export default Main;
